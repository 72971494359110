import React from "react";
import "./LockScreen.css";
import logoImg from "../../lib/logo/black-logo.png";
import { Link } from "react-router-dom";

const LockScreen = () => {
    return(
        <div className="lockscreen-main">
            <div className="lockscreen-navbar-c">
                <div className="lockscreen-navbar-left">
                    <div id="navbar-logo" className="left-navbar-item">
                        <Link to="/">
                            <img className="navbar-logo-link" src={logoImg} alt="user-pfp"/>
                        </Link>
                    </div>
                    <div className="left-navbar-item">
                        <Link className="lockscreen-navbar-button" to="/privacypolicy">Privacy Policy</Link>
                    </div>
                </div>
                <div className="lockscreen-navbar-right">
                    <div className="right-navbar-item">
                        <Link className="lockscreen-login-button" to="/register">Register</Link>
                    </div>
                    <div className="right-navbar-item">
                        <Link className="lockscreen-login-button" to="/login">Sign In</Link>
                    </div>
                </div>
            </div>
            <div className="lockscreen-central-c">
                <div className="lockscreen-display-c">
                    <h1 className="lockscreen-title">BossTala's Community</h1>
                    <p className="lockscreen-text">Join now</p>
                </div>
            </div>
        </div>
    );
};

export default LockScreen;