import { firestore } from "./firebase";
import { collection, doc, addDoc, getDoc, updateDoc, increment } from "firebase/firestore";
import { serverTimestamp } from 'firebase/firestore';

const getServerTimestamp = () => {
    return serverTimestamp();
};

const fetchDoc = async (collection_name, document_name) => {
    try {
        const fetched_doc = await getDoc(doc(firestore, collection_name, document_name));

        if (fetched_doc.exists()) {
            return fetched_doc.data();
        } else {
            console.log('Could not find document',document_name);
            return null;
        }
    } catch (error) {
        console.error('Error fetching document: ', error);
        return null;
    }
}

const createDocRandom = async (collection_name, document) => {
    try{
        await addDoc(collection(firestore, collection_name), document);
    } catch (error) {
        console.error('Error sending document: ', error);
    }
}

const getCollectionSize = async (collection_name) => {
    const statsDoc = await getDoc(doc(firestore, collection_name, "collection_stats"));

    if (statsDoc.exists()) {
        return statsDoc.data().size;
    } else {
        throw new Error("Collection stats document not found.");
    }
}

const incrementCollectionSize = async (collection_name) => {
    const docRef = doc(firestore, collection_name, "collection_stats");
    try{
        await updateDoc(docRef, {
            size: increment(1)
        });
    } catch (error) {
        console.error('Error incrementing collection size of',collection_name,":", error);
    }
}

export {
    getServerTimestamp,
    fetchDoc,
    createDocRandom,
    getCollectionSize,
    incrementCollectionSize,
}
