import React from "react";
import "./Game.css";

const Game = () => {
    return(
        <div className="game-main">
            <div className="game-central-c">
                <div className="game-title-c">
                    <h1 className="game-title">
                        DOWNLOAD BYOM INSTALLER
                    </h1>
                </div>
                <div className="game-content-c">
                    <div className="game-list-c">
                        <table>
                            <tr>
                                <th>Installer</th>
                                <th>Version</th>
                                <th>Multiplayer</th>
                                <th>Maps</th>
                            </tr>
                            <tr>
                                <td>
                                    <a className="game-link" href="https://www.exobyom.com/download/byom/1.0.0/ByomInstaller-1.0.0.exe" download>ByomInstaller-1.0.0</a>
                                </td>
                                <td>1.0.0</td>
                                <td>No</td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>
                                    <a className="game-link" href="https://www.exobyom.com/download/byom/1.0.1/ByomInstaller-1.0.1.exe" download>ByomInstaller-1.0.1</a>
                                </td>
                                <td>1.0.1</td>
                                <td>No</td>
                                <td>4</td>
                            </tr>
                            <tr>
                                <td>
                                    <a className="game-link" href="https://www.exobyom.com/download/byom/1.0.2/ByomInstaller-1.0.2.zip" download>ByomInstaller-1.0.2</a>
                                </td>
                                <td>1.0.2</td>
                                <td>Yes</td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>
                                    <a className="game-link" href="https://www.exobyom.com/download/byom/1.0.3/ByomInstaller-1.0.3.exe" download>ByomInstaller-1.0.3</a>
                                </td>
                                <td>1.0.3</td>
                                <td>Yes</td>
                                <td>4</td>
                            </tr>
                            <tr>
                                <td>
                                    <a className="game-link" href="https://www.exobyom.com/download/byom/1.0.4/ByomInstaller-1.0.4.exe" download>ByomInstaller-1.0.4</a>
                                </td>
                                <td>1.0.4</td>
                                <td>Yes</td>
                                <td>5</td>
                            </tr>
                        </table> 
                    </div>
                    <div className="game-description-c">
                        <p className="description">
                            Once you launched the installer, make sure to read the License, Before Install and After install prompts to be able to play Byom.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Game;