/* ================ VALUES ===============================================*/
const badWords_doc_name = "00BadWords00";
const max_forum_fetch = 100;

/* ================ USER ===============================================*/
const default_user_data = {
    creation:   0,
    email:       "",
    pfp:        'https://www.exobyom.com/users/default-profile-image.png',
    theme:      "#303030",
    type:       "Member",
    username:   ""
};

const profile_attribute_order = [
    "username",
    "email",
    "type",
    "theme",
    "creation"
];

/* ================ USER_UIDS ===============================================*/
const UID_CHRISTIAN = "VNDRUsRqIDYFVSnwkNYzjYcRHHy2";
const UID_CYRIL     = "J6FmeU3yUcOkDIj5iDeIrjf2Al32";

/* ================ DATABASE ===============================================*/
const CVStatsNS4_db_collection_name = "cvstats-ns4";

/* ================ CREDITS ===============================================*/
const tools = [
    "React",
    "Html",
    "Javascript",
    "CSS / SCSS",
    "Firebase",
    "cPanel",
    "o2switch",
    "FileZilla",
];

const credits = [
    "Goat:       Nilo",
    "Icons:      svgrepo.com",
    "Animations: Jon Kantner on Codepen",
]

/* ================ ERROR MESSAGES ===============================================*/
const error_existing_username = "Username Already Exists";
const error_me_test_pas = "Me test pas AFOU";

export{
    badWords_doc_name,
    max_forum_fetch,
    default_user_data,
    profile_attribute_order,
    UID_CHRISTIAN,
    UID_CYRIL,
    CVStatsNS4_db_collection_name,
    tools,
    credits,
    error_existing_username,
    error_me_test_pas
};