import React, { useState, useEffect } from "react";
import "./Register.css";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../backend/auth/AuthContext";
import { UserCreation } from "../../backend/user";
import { Link } from "react-router-dom";
import logo from "../../lib/logo/white-logo.png";
import {default_user_data} from "../../lib/constantes";

const Register = () => { 
    const [usernameInput, setUsernameInput] = useState('');

    const {empAuthRegister, user} = UserAuth();
    const navigate = useNavigate();

    const handleEmPRegister = async (e) => {

        e.preventDefault();

        // Read the form data
        const form = e.target;
        const formData = new FormData(form);

        const form_email = formData.get("email");
        const form_password = formData.get("password");

        setUsernameInput(formData.get("username"));

        try {
            await empAuthRegister(form_email, form_password);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if(user != null){
            UserCreation(user.uid, usernameInput, user.email, default_user_data.pfp);
            navigate("/");
        }
    }, [user, usernameInput, navigate]);

    return(
        <div className="register-main">
            <img className="register-logo" alt="logo" src={logo}/>
            <div className="register-form-box">
                <div className="register-form-value">
                    <form onSubmit={handleEmPRegister} method="post">
                        <h2 className="register-h2">Create account</h2>
                        <div className="register-inputbox">
                            <ion-icon name="username-outline"></ion-icon>
                            <input className="register-input" name="username" type="text"/>
                            <label className="register-label" for="username">Username</label>
                        </div>
                        <div className="register-inputbox">
                            <ion-icon name="lock-closed-outline"></ion-icon>
                            <input className="register-input" name="email" type="email"/>
                            <label className="register-label" for="email">Email</label>
                        </div>
                        <div className="register-inputbox">
                            <ion-icon name="lock-closed-outline"></ion-icon>
                            <input className="register-input" name="password" type="password"/>
                            <label className="register-label" for="password">Password</label>
                        </div>
                        
                        <button type="submit" className="register-button">
                            <span>Create new account</span>
                        </button>
                        <div className="register-register">
                            <p className="register-p"> Already registered ? <Link to="/login">Login</Link></p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Register;