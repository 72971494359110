function orderKeys(document, keyOrder) {
    const keys = Object.keys(document).sort((key1, key2) => keyOrder.indexOf(key1) - keyOrder.indexOf(key2));
  
    const orderedDocument = keys.reduce((acc, key) => {
      acc[key] = document[key];
      return acc;
    }, {});
    return orderedDocument;
}

export {
    orderKeys
}