import { firestore } from "./firebase";
import { getServerTimestamp } from './firestore';
import { collection, addDoc, getDocs, query, where } from "firebase/firestore";

const forumMessageFilter = async (forum_messages) => {
    const fetchedFamilyUsers = await getDocs(query(collection(firestore, "users"), where("type", "==", "Family")));
    const familyUIDs = [];
    fetchedFamilyUsers.forEach((doc) => {
        familyUIDs.push(doc.id);
    });
    
    return forum_messages.filter(document => !familyUIDs.includes(document.user_uid));
}

const sendNewMessage = async (user_uid, user_photo, user_name, message_input) =>{
    var message_document = {
        text:       message_input,
        time:       getServerTimestamp(),
        user_name,
        user_uid,
        user_photo
    };
    
    try{
        await addDoc(collection(firestore, "forum"), message_document);
    } catch (error) {
        console.error('Error sending at #5: ', error);
    }
}

export {
    sendNewMessage,
    forumMessageFilter
}