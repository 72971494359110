import React from "react";
import "./Privacy.css";
import logoImg from "../../lib/logo/black-logo.png";
import { Link } from "react-router-dom";

const Privacy = () => {
    return(
        <div className="privacy-main">
            <div className="privacy-central-c">
                <div className="privacy-title-c">
                    <h1 className="privacy-title">
                        Matala Games PRIVACY POLICY
                    </h1>
                </div>
                <div className="privacy-content-c">
                    <p className="privacy-content">
                    <h1>Privacy Policy for Matala Games</h1>
                    <p>This policy is effective as of 2023-05-31</p>
                    <p>Matala Games ("we," "us," "our") respects your privacy and is committed to protecting your personal information.</p>
                    <p>This Privacy Policy describes how we collect, use,and disclose information when you visit our website or use our services, including our indie games (collectively, "Services").</p>
                    <p>Please read this Privacy Policy carefully. By accessing or using our Services, you agree to this Privacy Policy. If you do not agree with this Privacy Policy, please do not access or use our Services.</p>
                    <p> <strong>Information Collection and Use</strong> </p>
                    <div>
                        <p>
                            The app does use third-party services that may collect
                            information used to identify you.
                        </p>
                        <p>
                            Link to the privacy policy of third-party service providers used
                            by the app
                        </p>
                    </div>
                    <p><strong>Cookies</strong></p>
                    <p>
                        Cookies are files with a small amount of data that are
                        commonly used as anonymous unique identifiers. These are sent
                        to your browser from the websites that you visit and are
                        stored on your device's internal memory.
                    </p>
                    <p>
                        This Service does not use these “cookies” explicitly. However,
                        the app may use third-party code and libraries that use
                        “cookies” to collect information and improve their services.
                        You have the option to either accept or refuse these cookies
                        and know when a cookie is being sent to your device. If you
                        choose to refuse our cookies, you may not be able to use some
                        portions of this Service.
                    </p>
                    <p><strong>Service Providers</strong></p>
                    <p>
                        I may employ third-party companies and
                        individuals due to the following reasons:
                    </p>
                    <ul><li>To facilitate our Service;</li> <li>To provide the Service on our behalf;</li> <li>To perform Service-related services; or</li> <li>To assist us in analyzing how our Service is used.</li></ul>
                    <p>
                        I want to inform users of this Service
                        that these third parties have access to their Personal
                        Information. The reason is to perform the tasks assigned to
                        them on our behalf. However, they are obligated not to
                        disclose or use the information for any other purpose.
                    </p>
                    <p><strong>Security</strong></p>
                    <p>
                        I value your trust in providing us your
                        Personal Information, thus we are striving to use commercially
                        acceptable means of protecting it. But remember that no method
                        of transmission over the internet, or method of electronic
                        storage is 100% secure and reliable, and I cannot
                        guarantee its absolute security.
                    </p>
                    <p><strong>Links to Other Sites</strong></p>
                    <p>
                        This Service may contain links to other sites. If you click on
                        a third-party link, you will be directed to that site. Note
                        that these external sites are not operated by me.
                        Therefore, I strongly advise you to review the
                        Privacy Policy of these websites. I have
                        no control over and assume no responsibility for the content,
                        privacy policies, or practices of any third-party sites or
                        services.
                    </p> 
                    <p><strong>Children’s Privacy</strong></p>
                    <div> <p>
                            These Services do not address anyone under the age of 13.
                            I do not knowingly collect personally
                            identifiable information from children under 13 years of age. In the case
                            I discover that a child under 13 has provided
                            me with personal information, I immediately
                            delete this from our servers. If you are a parent or guardian
                            and you are aware that your child has provided us with
                            personal information, please contact me so that
                            I will be able to do the necessary actions.
                    </p></div> 
                    <p><strong>Changes to This Privacy Policy</strong></p>
                    <p>
                        I may update our Privacy Policy from
                        time to time. Thus, you are advised to review this page
                        periodically for any changes. I will
                        notify you of any changes by posting the new Privacy Policy on
                        this page.
                    </p>
                    <p><strong>Contact Us</strong></p>
                    <p>
                        If you have any questions or suggestions about my
                            Privacy Policy, do not hesitate to contact me at help@exobyom.com.
                    </p>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Privacy;