import React, { useState, useEffect } from "react";
import "./ProfileRequest.css";
import { UserAuth } from "../../../backend/auth/AuthContext";
import { fetchDoc } from "../../../backend/firestore";
import { fetchUserData, sendProfileRequest } from "../../../backend/user";
import { Link, useNavigate } from "react-router-dom";
import back_svg from "../../../lib/svg/round-alt-arrow-left.svg";

const ProfileRequest = () => {
    /* Current User */
    const { getCurrentUser } = UserAuth();
    var userContext = getCurrentUser();
    const [userType, setUserType] = useState('');
    const [userTypes, setUserTypes] = useState([]);
    const [justifInput, setJustifInput] = useState('');
    const navigate = useNavigate();

    const handleSelectChange = async (e) => {
        e.preventDefault();
        setUserType(e.target.value);
    };

    const handleRequestForm = async (e) => {
        e.preventDefault();
        sendProfileRequest(userContext.uid, userType, justifInput);
        navigate("/profile");
    }

    /* GET USER THEME */    
    useEffect(() => {
        const getFirestoreData = async () => {
            // Fetch User Data
            const received_user_data = await fetchUserData(userContext.uid);
            if (received_user_data) {
                setUserType(received_user_data.type);
            }
            //Fetch User Types
            const received_user_settings = await fetchDoc("settings", "user");
            if (received_user_settings) {
                setUserTypes(received_user_settings.types);
            }
        };

        getFirestoreData();
    }, [userContext]);

    return(
        <div className="pr-main">
            <form className="pr-box" onSubmit={handleRequestForm}>
                <div className="pr-title-box">
                    <Link id="pr-back-link" to="/profile">
                        <img id="pr-back" src={back_svg} alt="back"/>
                    </Link>
                    <p id="pr-title">Request user type</p>
                </div>
                <div className="pr-item">
                    <p className="pr-item-title">Select new user type :</p>
                    <select className="pr-select" value={userType} onChange={handleSelectChange}>
                        <option value="" disabled>User Types</option>
                        {userTypes.map((option, index) => (
                        <option key={index} value={option}>
                            {option}
                        </option>
                        ))}
                    </select>
                </div>
                <div className="pr-item">
                    <p className="pr-item-title">Justification (t'es qui?) :</p>
                    <textarea id="pr-justif-input" value={justifInput} onChange={(e) => setJustifInput(e.target.value)} type="text" required/>
                </div>
                <button id="pr-button" type="submit">
                    Send Request
                </button>
            </form>
        </div>
    );
};

export default ProfileRequest;