import React from "react";
import { Route, Routes } from "react-router-dom";
import { AuthContextProvider, UserAuth } from "./backend/auth/AuthContext";
import Protected  from "./backend/auth/Protected";
import Login      from "./pages/Login/Login";
import Register   from "./pages/Register/Register";
import LockScreen from "./pages/LockScreen/LockScreen";
import Layout    from "./components/Layout/Layout";
import Home      from "./pages/Home/Home";
import Game  from "./pages/Game/Game";
import Privacy   from "./pages/Privacy/Privacy";
import Forum     from "./pages/Forum/Forum";
import Profile   from "./pages/Profile/Profile";
import ProfileModify  from "./pages/Profile/Modify/ProfileModify";
import ProfileRequest from "./pages/Profile/Request/ProfileRequest";
import LoadingFryingPan from "./pages/Loading/FryingPan/LoadingFryingPan";
import LoadingBouncing from "./pages/Loading/Bouncing/LoadingBouncing";
import CVStatsNS4 from "./pages/private/CVStatsNS4/CVStatsNS4";

const AppContent = () => {
  const {isConnected } = UserAuth();

  if(!isConnected()){
    return <LockScreen/>;
  }

  return(
    <Layout>
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/privacypolicy"    element={<Privacy />}/>
        <Route path="/game"             element={<Protected><Game /></Protected>}/>
        <Route path="/database"         element={<Protected><LoadingBouncing /></Protected>}/>
        <Route path="/cooking"          element={<Protected><LoadingFryingPan /></Protected>}/>
        <Route path="/forum"            element={<Protected><Forum /></Protected>}/>
        <Route path="/profile"          element={<Protected><Profile /></Protected>}/>
        <Route path="/profile/modify"   element={<Protected><ProfileModify /></Protected>}/>
        <Route path="/profile/request"  element={<Protected><ProfileRequest /></Protected>}/>
        <Route path="/cv-stats-ns4"     element={<Protected><CVStatsNS4 /></Protected>}/>
      </Routes>
    </Layout>
  );
}

function App() {
  return (
    <AuthContextProvider>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />}/>
        <Route path="/*" element={<AppContent />} />
      </Routes>
    </AuthContextProvider>
  );
}

export default App;
