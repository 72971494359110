import React, { useEffect, useState } from "react";
import "./CVStatsNS4.css";
import { createDocRandom, getCollectionSize, getServerTimestamp, incrementCollectionSize } from "../../../backend/firestore";
import { CVStatsNS4_db_collection_name, UID_CHRISTIAN, UID_CYRIL } from "../../../lib/constantes";
import { UserAuth } from "../../../backend/auth/AuthContext";
import { useNavigate } from "react-router-dom";

const CVStatsNS4 = () => {
    
    const navigate = useNavigate();
    const { getCurrentUser } = UserAuth();
    const user = getCurrentUser();
    const user_uid = user.uid;

    const [perso11, setPerso11] = useState('');
    const [perso12, setPerso12] = useState('');
    const [perso13, setPerso13] = useState('');
    const [perso21, setPerso21] = useState('');
    const [perso22, setPerso22] = useState('');
    const [perso23, setPerso23] = useState('');
    
    const [gameMap, setGameMap] = useState('');
    const [note, setNote] = useState('');
    const [score1, setScore1] = useState(0);
    const [score2, setScore2] = useState(0);
    const [winner, setWinner] = useState('');

    const clearCVSNS4Input = () => {
        setPerso11('');
        setPerso12('');
        setPerso13('');
        setPerso21('');
        setPerso22('');
        setPerso23('');
        setGameMap('');
        setScore1(0);
        setScore2(0);
        setNote('');
        setWinner('');
    };

    const handleStatRegister = async (e) => {
        e.preventDefault();
        if(user_uid===UID_CHRISTIAN || user_uid===UID_CYRIL){
            try {
                // 1. Get the current size from the collection_stats document
                const currentSize = await getCollectionSize(CVStatsNS4_db_collection_name);
        
                // 2. Add a new stat document with the current size as its ID
                var creator = "";
                if(user_uid===UID_CHRISTIAN)   creator = "Christian";
                else if (user_uid===UID_CYRIL) creator = "Cyril";
                else creator = "INTRUDER";
    
                const new_stat_doc = {
                    perso_ch: [perso11,perso12, perso13],
                    perso_cy: [perso21,perso22, perso23],
                    map: gameMap,
                    score: [score1,score2],
                    note: note,
                    winner: winner,
                    id: currentSize+1,
                    date: getServerTimestamp(),
                    stat_by: creator
                }
                await createDocRandom(CVStatsNS4_db_collection_name, new_stat_doc);
        
                // 3. Increment the size in the collection_stats document
                await incrementCollectionSize(CVStatsNS4_db_collection_name);
            } catch (error) {
                console.log("Error sending stat:",error);
            }
        }
        else{
            console.log("Error trying to send stat: PERMISSION_DENIED");
            navigate("/");
        }
        
    };

    useEffect(() => {
        if(!(user.uid===UID_CHRISTIAN || user.uid===UID_CYRIL)){
            console.log("Error accessing page: ACCESS_DENIED");
            navigate("/");
        }
    }, [navigate, user]);

    return(
        <div className="cvsns4-main">
            <form className="cvsns4-form" onSubmit={handleStatRegister}>
                <div className="cvsns4-form-input">
                    <div className="cvsns4-form-top">
                        <div className="cvsns4-persos">
                            <input className="cvsns4-perso" value={perso11} onChange={(e) => setPerso11(e.target.value)} type="text" required/>
                            <input className="cvsns4-perso" value={perso12} onChange={(e) => setPerso12(e.target.value)} type="text" />
                            <input className="cvsns4-perso" value={perso13} onChange={(e) => setPerso13(e.target.value)} type="text" />
                        </div>
                        <div className="cvsns4-persos">
                            <input className="cvsns4-perso" value={perso21} onChange={(e) => setPerso21(e.target.value)} type="text" required/>
                            <input className="cvsns4-perso" value={perso22} onChange={(e) => setPerso22(e.target.value)} type="text" />
                            <input className="cvsns4-perso" value={perso23} onChange={(e) => setPerso23(e.target.value)} type="text" />
                        </div>     
                    </div>
                    <div className="cvsns4-form-bottom">
                        <div className="cvsns4-form-bottom-layer">
                            <input id="cvsns4-map"      value={gameMap} onChange={(e) => setGameMap(e.target.value)} type="text" required/>
                        </div>
                        <div className="cvsns4-form-bottom-layer">
                            <input className="cvsns4-score"   value={score1}  onChange={(e) => setScore1(Number(e.target.value))}  type="number" min="0" max="2"required/>
                            <p id="cvsns4-score-separator">-</p>
                            <input className="cvsns4-score"   value={score2}  onChange={(e) => setScore2(Number(e.target.value))}  type="number" min="0" max="2"required/>
                        </div>
                        <div className="cvsns4-form-bottom-layer">
                            <input id="cvsns4-note"     value={note}    onChange={(e) => setNote(e.target.value)}    type="text" required/>
                            <input id="cvsns4-winner"   value={winner}  onChange={(e) => setWinner(e.target.value)}  type="text" required/>
                        </div>
                    </div>
                </div>
                <div className="cvsns4-form-submit">
                    <button className="cvsns4-form-button" type="submit">
                        Send Stat
                    </button>
                    <button className="cvsns4-form-button" onClick={clearCVSNS4Input}>
                        Clear
                    </button>
                </div>
            </form>
        </div>
    );
};

export default CVStatsNS4;