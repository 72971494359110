import React, { useState, useEffect } from "react";
import "./Forum.css";
import { collection, onSnapshot, query, orderBy, limit } from "firebase/firestore";
import { forumMessageFilter, sendNewMessage } from "../../backend/forum";
import send_svg from "../../lib/svg/round-arrow-up.svg";
import { UserAuth } from "../../backend/auth/AuthContext";
import { fetchUserData } from "../../backend/user";
import { firestore } from "../../backend/firebase";
import { checkBadWords } from "../../backend/moderator";
import { default_user_data, max_forum_fetch } from "../../lib/constantes";

/* CSS CLASS */
const css_sent     = "forum-msg-sent";
const css_received = "forum-msg-received";

const Forum = () => {

    const { getCurrentUser } = UserAuth();
    var user = getCurrentUser();
    const [userType, setUserType] = useState(default_user_data.type);

    /* GET USER TYPE */
    useEffect(() => {
        const getUserData = async () => {
            const received_user_data = await fetchUserData(user.uid);
            if (received_user_data && received_user_data.type) {
                setUserType(received_user_data.type);
                console.log("Setting user type to",received_user_data.type);
            }
        };

        getUserData();
    }, [user]);

    /* SEND MESSAGES */
    const [messageInput, setMessageInput] = useState('');
    const handleNewMessage = async (e) => {
        e.preventDefault();
        const user_document = await fetchUserData(user.uid);
        const new_message   = await checkBadWords(messageInput);
        if(messageInput){
            sendNewMessage(user.uid, user_document.pfp, user_document.username, new_message);
            setMessageInput('');
        }
        else{
            /* Empty input */
        }
    }

    /* FETCH MESSAGES */
    const [messages, setMessages] = useState([]);
    useEffect( () => {
        const unsubscribe = onSnapshot(query(collection(firestore, "forum"), orderBy("time", "desc"), limit(max_forum_fetch)), async (query) => {
            const messagesData = query.docs.map(doc => ({
                messageClass: (user.uid === doc.data().user_uid) ? css_sent : css_received,
                msg_id: doc.id,
                ...doc.data()
            }));
            if(userType!=="Family" && userType!=="Owner"){
                console.log("filtering");
                const filteredMessages = await forumMessageFilter(messagesData);
                setMessages(filteredMessages);
            }
            else{
                setMessages(messagesData);
            }
        });
        
        // Cleanup listener on unmount
        return () => unsubscribe();
    }, [user.uid, userType]);

    

    return(
        <div className="forum-box">
            <div className="forum-chat">
                {messages && messages.map(message => <ForumMessage key={message.id} msg={message}/>)}
            </div>
            
            <form className="forum-input-box" onSubmit={handleNewMessage}>
                <input className="forum-input" value={messageInput} onChange = {(e) => setMessageInput(e.target.value)} type="text" />
                <button type="submit" className="forum-send-button">
                    <img className="forum-send-svg" src={send_svg} alt="send"/>
                </button>
            </form>
        </div>
    );
};

const ForumMessage = ({msg}) => {
    const { messageClass, user_photo, user_name, text, time } = msg;
    /* Convert time */
    let date = '';
    if(time){
        date = new Date(time.seconds * 1000).toLocaleString();
    }
    
    return (
        <div className="forum-msg-box">
            { messageClass===css_received ? <img id="forum-msg-pfp" src={user_photo} alt="pfp"/> : <></>}
            <div className={messageClass}>
                { messageClass===css_received ? <p className="forum-msg-username">{user_name}</p> : <></> }
                <div className={`${messageClass}-content`}>
                    <p className="forum-msg-text">{text}</p>
                    <p className="forum-msg-time">{date}</p>
                </div>
            </div>
        </div>
    )
}

export default Forum;