import { firestore } from "./firebase";
import { getServerTimestamp } from './firestore';
import { doc, addDoc, collection, getDoc, updateDoc, setDoc } from "firebase/firestore";
import { default_user_data } from '../lib/constantes';

/* USER CREATION */
const check_google_user = async (user_uid, user_displayName, user_email, user_photoURL) => {
    var new_user = true;
    try{
        const docRef = doc(firestore, "users", user_uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            new_user = false;
        }

    } catch (error) {
        console.error('Error fetching users at #1: ', error);
    }

    if(new_user){
        console.log(user_email,"is new, creating new user");
        UserCreation(user_uid, user_displayName, user_email, user_photoURL);
    }
    else{
        console.log(user_email,"already exist, no user creation");
        try{
            await updateDoc(doc(firestore, "users", user_uid), {
                pfp: user_photoURL
              });
        } catch (error) {
            console.error('Error updating user at #2: ', error);
        }
    }
};

const UserCreation = async (new_uid, new_username, new_email, new_userphoto) => {
    var new_timestamp = getServerTimestamp();
    var new_theme = default_user_data.theme;
    var new_type  = default_user_data.type;

    var user_document = {
        creation:   new_timestamp,
        email:      new_email,
        pfp:        new_userphoto,
        theme:      new_theme,
        type:       new_type,
        username:   new_username
    };

    try{
        await setDoc(doc(firestore, "users", new_uid), user_document);
    } catch (error) {
        console.error('Error creating user at #3: ', error);
    }
    
    console.log("CREATED NEW USER\n ID:", new_uid,
                "\nTimestamp:",new_timestamp,
                "\nEmail:", new_email,
                "\nUserphoto:",new_userphoto,
                "\nTheme:",new_theme,
                "\nType:",new_type,
                "\nUsername:",new_username);
    
};

const fetchUserData = async (current_uid) => {
    try {
        const user_doc = await getDoc(doc(firestore, 'users', current_uid));

        if (user_doc.exists()) {
            return user_doc.data();
        } else {
            console.log('Could not find document',current_uid);
            return null;
        }
    } catch (error) {
        console.error('Error fetching document of user: ', error);
        return null;
    }
}

const sendProfileRequest = async (user_uid, user_type, justification) => {
    var request = {
        user_uid,
        user_type,
        justification,
        time: getServerTimestamp(),
    };
    
    try{
        await addDoc(collection(firestore, "requests"), request);
    } catch (error) {
        console.error('Error sending request: ', error);
    }
}

export{
    UserCreation,
    check_google_user,
    fetchUserData,
    sendProfileRequest
};