import React from "react";
import "./Home.css";

const Home = () => {
    return(
        <div className="home-main">
            
        </div>
    );
};

export default Home;