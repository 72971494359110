import React, { useEffect } from 'react';
import "./Layout.css";
import { UserAuth } from "../../backend/auth/AuthContext";
import { fetchUserData } from '../../backend/user';
import { default_user_data, UID_CHRISTIAN, UID_CYRIL } from '../../lib/constantes';
import logoImg from "../../lib/logo/white-logo.png";
import game_svg from "../../lib/svg/game.svg";
import database_svg from "../../lib/svg/database.svg";
import cooking_svg from "../../lib/svg/cooking.svg";
import policy_svg from "../../lib/svg/shield-user.svg";
import forum_svg from "../../lib/svg/users-group.svg";
import { Link } from "react-router-dom";

const Layout = ({ children }) => {
    /* USER DATA */
    const { getCurrentUser } = UserAuth();
    var user = getCurrentUser();

    var userphoto = (user.photoURL) ? user.photoURL : default_user_data.pfp;

    useEffect(() => {
        const getUserData = async () => {
            const received_user_data = await fetchUserData(user.uid);
            if (received_user_data && received_user_data.theme) {
                document.documentElement.style.setProperty('--color-theme', received_user_data.theme);
            }
        };

        getUserData();
    }, [user]);

    /* NAVIGATION LINKS */
    var navLinks = [
        { name: "Game",     path: "/game",          icon: game_svg },
        { name: "Database", path: "/database",      icon: database_svg },
        { name: "Cooking",  path: "/cooking",       icon: cooking_svg },
        { name: "Forum",    path: "/forum",         icon: forum_svg},
        { name: "Privacy",  path: "/privacypolicy", icon: policy_svg},
    ];

    if(user.uid===UID_CHRISTIAN || user.uid===UID_CYRIL){
        navLinks = [...navLinks, { name: "Stats",    path: "/cv-stats-ns4",  icon: database_svg }]
    };

    return (
        <div className="layout-box">
            <div className="sidebar">
                <div className="sidebar-logo">
                    <Link to="/">
                        <img id="logo" src={logoImg} alt="Logo" />
                    </Link>
                </div>
                
                <div className="sidebar-content">
                    <div className="sidebar-link-box">
                    {
                        navLinks.map((item, index) => (
                            <Link className="sidebar-link" key={index} to={item.path}>
                                <img className="sidebar-link-svg" src={item.icon} alt="dbsvg" />
                                {item.name}
                            </Link>
                        ))
                    }
                    </div>
                </div>
                <div className="sidebar-account">
                    <Link id="user-photo-link" to="/profile">
                        <img id="user-photo" src={userphoto} alt="Invalid UserPhoto" />
                    </Link>
                </div>
            </div>

            <>
                {children}
            </>
        </div>
    );
}

export default Layout;