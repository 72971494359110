import React, { useEffect } from "react";
import "./Login.css";
import { GoogleButton } from "react-google-button";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../backend/auth/AuthContext";
import { check_google_user } from "../../backend/user";
import { Link } from "react-router-dom";
import logo from "../../lib/logo/white-logo.png";

const Login = () => {

    const {googleLogin, empLogin, user} = UserAuth();
    const navigate = useNavigate();

    const handleGoogleLogin = async () => {
        try{
            await googleLogin()
        } catch(error) {
            console.log(error);
        }
    }

    const handleEmPLogin = async (e) => {
        navigate("/");
        e.preventDefault();

        // Read the form data
        const form = e.target;
        const formData = new FormData(form);

        var email = formData.get("email");
        var password = formData.get("password");

        try {
            await empLogin(email, password)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if(user != null){
            check_google_user(user.uid, user.displayName, user.email, user.photoURL);
            navigate("/");
        }
    }, [user, navigate]);

    return(
        <div className="login-main">
            <img className="login-logo" alt="logo" src={logo}/>
            <div className="login-form-box">
                <div className="login-form-value">
                    <form onSubmit={handleEmPLogin} method="post">
                        <h2 className="login-h2">Login</h2>
                        <div className="login-inputbox">
                            <ion-icon name="username-outline"></ion-icon>
                            <input className="login-input" name="email" type="email" required/>
                            <label className="login-label" for="email">Email</label>
                        </div>
                        <div className="login-inputbox">
                            <ion-icon name="lock-closed-outline"></ion-icon>
                            <input className="login-input" name="password" type="password" required/>
                            <label className="login-label" for="password">Password</label>
                        </div>
                        <div className="login-forget">
                            <label className="login-label" for=""><input className="login-input" type="checkbox"/>Remember Me</label>
                            <a id='login-reme' href="https://www.exobyom.com">Forgot Password ?</a>
                        
                        </div>
                        <button type="submit" className="login-button">
                            <span>Log In</span>
                        </button>
                        <div className="login-register">
                            <p className="login-p"> New ? <Link to="/register">Create an account</Link></p>
                        </div>
                        <GoogleButton className="login-google-login" onClick={handleGoogleLogin}/>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;