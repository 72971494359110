import { firestore } from "./firebase";
import { doc, getDoc } from "firebase/firestore";
import { badWords_doc_name } from "../lib/constantes";

const getBadWords = async () => {
    try {
        const badWordsDoc = await getDoc(doc(firestore, 'moderation', badWords_doc_name));
        return badWordsDoc.data().list;
        
    } catch (error) {
        console.error('Error fetching badWords: ', error);
        return [];
    }
};

const checkBadWords = async(text) => {
    let newText = text.toLowerCase();

    let badWords = await getBadWords();

    badWords.forEach((badWord) => {
        const regex = new RegExp(`\\b${badWord}\\b`, 'gi');
        newText = newText.replace(regex, '*'.repeat(badWord.length));
      });
    
    return newText;
};

export {
    checkBadWords
};