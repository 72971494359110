import React, { useState, useEffect } from 'react';
import "./Profile.css";
import { UserAuth } from "../../backend/auth/AuthContext";
import { default_user_data, profile_attribute_order } from '../../lib/constantes';
import { onSnapshot, doc } from 'firebase/firestore';
import { firestore } from '../../backend/firebase';
import { orderKeys } from '../../lib/functions';
import { Link } from "react-router-dom";
import logout_svg from "../../lib/svg/logout-right.svg"

const Profile = () => {
    /* USER DATA */
    const { getCurrentUser } = UserAuth();
    var userContext = getCurrentUser();

    const [userData, setUserData] = useState(default_user_data);
    
    useEffect( () => {
        const unsubscribe = onSnapshot(doc(firestore, 'users', userContext.uid), (doc) => {
            const newUserData = doc.data();
            newUserData.creation = new Date(newUserData.creation.seconds * 1000).toLocaleString();
            //Alphabetic sort: const newUserDataSorted = Object.fromEntries(Object.keys(newUserData).sort().map(key => [key, newUserData[key]]));
            const newUserDataSorted = orderKeys(newUserData, profile_attribute_order);
            setUserData(newUserDataSorted);
        });
        
        // Cleanup listener on unmount
        return () => unsubscribe();
    }, [userContext.uid]);

    /*Handle logout*/
    const { logOut } = UserAuth();
    const handleLogOut = async () => {
        try {
            await logOut();
        } catch (error) {
            console.log(error);
        }
    };

    return (
    <div className='profile-main-box'>
        <div className="profile-top-box">
            <div className="profile-top-c">
                <div className="profile-top-user">
                    <img id="profile-user-photo" src={userData.pfp} alt=" " />
                    <p className='profile-user-name'>{userData.username}</p>
                </div>
                <div className="profile-top-card">
                    {(userData.type==="") ? <p></p> :
                    <>
                        <div id='profile-card-bg'>{userData.type} card</div>
                        <div id='profile-card-fg'>{userData.type} card</div>
                    </>
                    }
                </div>
            </div>
            <div className="profile-content-box">
                <div className="profile-attributes">
                    {Object.entries(userData).map(([key, value]) => (<ProfileAttribute atb_name={key} atb_value={value}/>))}
                </div>
                <div className="profile-modify">
                    <div className="profile-modify-item">
                        <Link className="profile-modify-link" to="/profile/modify">
                            <p className='profile-modify-text'>Modify Profile</p>
                        </Link>
                    </div>

                    <div className="profile-modify-item">
                        <Link className="profile-modify-link" to="/profile/request">
                            <p className='profile-modify-text'>Change User Type</p>
                        </Link>
                    </div>

                    <div className="profile-modify-item">
                        <button className="profile-logout-button" onClick={handleLogOut}>
                            <span>Disconnect</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
};

const ProfileAttribute = ({atb_name, atb_value}) => {
    if(atb_name==="pfp") return <></>;
    if(atb_name==="theme") return (
        <div key={atb_name} className="profile-attribute-box">
            <p className='profile-key'>{atb_name}:</p>
            <div style={{
                backgroundColor: atb_value,
                width: '1.7rem',
                height: '1.7rem',
                borderRadius: '50%'
            }}/>
        </div>
    );
    return (
        <div key={atb_name} className="profile-attribute-box">
            <p className='profile-key'>{atb_name}:</p>
            <p className='profile-value'>{atb_value}</p>
        </div>
    );
};

export default Profile;