import { useContext, createContext, useEffect, useState } from "react";
import {
    GoogleAuthProvider,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signInWithPopup,
    //signInWithRedirect,
    signOut,
    //updateProfile,
    onAuthStateChanged,
    updateCurrentUser
} from "firebase/auth";

import {auth} from "../firebase";

const AuthContext = createContext();

export const AuthContextProvider = ({children}) => {
    const [user, setUser] = useState({});

    const logCurrentUser = () => {
        updateCurrentUser(auth, user);
        //var current = auth.currentUser;
        //console.log("User:", current);
    }

    const getCurrentUser = () => {
        if(auth.currentUser){
            return user;
        } else{
            return null;
        }
    }

    const isConnected = () => {
        if(auth.currentUser){
            return true;
        } else{
            return false;
        }
    }

    const googleLogin = () => {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider);
        // signInWithRedirect(auth, provider);
    };

    const empLogin = (email, password) => {
        signInWithEmailAndPassword(auth, email, password);
    }

    const empAuthRegister = (email, password) => {
        // console.log("empRegister");
        createUserWithEmailAndPassword(auth, email, password);
        // console.log("finished empRegister");
    };

    const logOut = () => {
        signOut(auth);
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            //console.log("unsubscribe useEffect");
            setUser(currentUser);
            //console.log('User', currentUser);
        });
        return () => {
            unsubscribe();
        }
    },[setUser]);

    return (
        <AuthContext.Provider value={{googleLogin, empLogin, empAuthRegister, logCurrentUser, getCurrentUser, isConnected, logOut, user}}>
            {children}
        </AuthContext.Provider>
    );
}

export const UserAuth = () => {
    return useContext(AuthContext)
}