import React, { useState, useEffect } from "react";
import "./ProfileModify.css";
import { UserAuth } from "../../../backend/auth/AuthContext";
import { fetchUserData } from "../../../backend/user";
import { default_user_data } from "../../../lib/constantes";
import { firestore } from "../../../backend/firebase";
import { doc, getDocs, updateDoc, query, collection, where } from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import back_svg from "../../../lib/svg/round-alt-arrow-left.svg";
import { error_existing_username, error_me_test_pas } from "../../../lib/constantes";

const ProfileModify = () => {
    /* Current User */
    const { getCurrentUser } = UserAuth();
    var userContext = getCurrentUser();
    const [currentUsername, setCurrentUsername] = useState('');
    const [usernameInput, setUsernameInput] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [colorInput, setColorInput] = useState(default_user_data.theme);
    const navigate = useNavigate();

    const sendProfileChanges = async (e) => {
        e.preventDefault();
        
        /* Check for existing usernames */
        const fetchedUsernames = await getDocs(query(collection(firestore, "users"), where("username", "==", usernameInput)));
        const existingUsernames = [];
        fetchedUsernames.forEach((doc) => {
            if(doc.id!==userContext.uid){
                existingUsernames.push(doc.data());
            }
        });
        if(existingUsernames.length){
            setUsernameError(error_existing_username);
            document.getElementById('pm-username-input').style.border = "red 2px solid";
        }
        
        /* Check if he tested me */
        else if(usernameInput===currentUsername){
            setUsernameError(error_me_test_pas);
            document.getElementById('pm-username-input').style.border = "red 2px solid";
        }

        /* Update Profile */
        else{
            setUsernameError('');
            const changes = { theme: colorInput};
            if(usernameInput!=='') changes.username = usernameInput;
            document.getElementById('pm-username-input').style.border = "var(--color-theme) 2px solid";
            await updateDoc(doc(firestore, "users", userContext.uid), changes);
            navigate("/profile");
            window.location.reload();
        }
    }

    /* GET USER THEME */    
    useEffect(() => {
        const getUserData = async () => {
            const received_user_data = await fetchUserData(userContext.uid);
            if (received_user_data) {
                setColorInput(received_user_data.theme);
                setCurrentUsername(received_user_data.username);
            }
        };

        getUserData();
    }, [userContext]);

    return(
        <div className="pm-main">
            <form className="pm-box" onSubmit={sendProfileChanges}>
                <div className="pm-title-box">
                    <Link id="pm-back-link" to="/profile">
                        <img id="pm-back" src={back_svg} alt="back"/>
                    </Link>
                    <p id="pm-title">Modify your profile</p>
                </div>
                <div className="pm-item">
                    <p className="pm-item-title">Enter New Username :</p>
                    <input id="pm-username-input" value={usernameInput} onChange={(e) => setUsernameInput(e.target.value)} type="text" />
                    <p id="pm-username-error">{usernameError}</p>
                </div>
                <div className="pm-item">
                    <p className="pm-item-title">Select New Theme :</p>
                    <input id="pm-color-input" value={colorInput} onChange={(e) => setColorInput(e.target.value)} type="color"/>
                </div>
                <button id="pm-button" type="submit">
                    Save Changes
                </button>
            </form>
        </div>
    );
};

export default ProfileModify;